<template>
  <div class="transaction-ledger-wrapper">
    <Permission/>
    <Breadcrumb/>
    <LedgerIndex>
      <div>
        <div class="title">Corporation Transaction Ledger</div>
        <div class="subtitle">{{ corporationName }}</div>
      </div>
    </LedgerIndex>
  </div>
</template>
<script>
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import corpDetailsMixin from '@utils/mixins/addy-plus-corporation-details-from-side-menu-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import LedgerIndex from '@views/addy_plus/corporations/csr/Index.vue'

export default {
  title: 'Corporation Transaction Ledger',
  mixins: [pageTitleMixin, corpDetailsMixin],
  components: {
    Permission,
    Breadcrumb,
    LedgerIndex,
  },
}
</script>
